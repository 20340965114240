<template>
  <v-container fluid>
    <v-row no-gutters align='center' justify='center'>
      <div class='text-h5'>
        {{$t('CUSTOMERS')}}
      </div>

      <v-btn icon class='ml-3 mt-1' @click='getData'>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-spacer />

      <v-btn text @click='detailsDialog = true'>
        <v-icon class='mr-2'>
          mdi-account-plus-outline
        </v-icon>
        {{$t('NEW_CUSTOMER')}}
      </v-btn>
    </v-row>
    <v-divider class='my-3' />

    <v-row no-gutters>
      <v-card flat class='wd-100'>
        <v-card-title class='py-4'>
          <v-btn class='mt-3 mr-5' color='primary' @click='openExportDialog()'> {{ $t('EXPORT') }} </v-btn>

          <v-spacer />

          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            :label='$t("SEARCH")'
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          class='wd-100'
          multi-sort
          :search='search'
          :headers='customerTableHeaders'
          :items='list'
          :item-class='(item) => getTableRowClass(item)'
          :footer-props='{
            itemsPerPageOptions: [50, 100, 300, -1]
          }'
        >
          <template #item.name='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.cnpj='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.cnpj}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.email='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.email}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.acronym='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.acronym}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.allowFingerprintSignin='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.allowFingerprintSignin ? $t('ABLE') : $t('DISABLED') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.createdAt='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{formattedDate(item.createdAt)}}
                </div>
              </template>
              {{formattedDateInWords(item.createdAt)}}
            </v-tooltip>
          </template>

          <template #item.updatedAt='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{formattedDate(item.updatedAt)}}
                </div>
              </template>
              {{formattedDateInWords(item.updatedAt)}}
            </v-tooltip>
          </template>

          <template #item.actions='{ item }'>
            <v-menu
              bottom
              left
            >
              <template #activator='{ on, attrs }'>
                <v-btn
                  icon
                  v-bind='attrs'
                  v-on='on'
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class='pa-0'>
                <v-list-item @click='handleOnClickEdit(item.id)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('EDIT_REGISTER')}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

    <!-- ---------------------------------------------------------------- -->
    <!-- DIALOGS -->
    <!-- ---------------------------------------------------------------- -->
    <v-dialog v-model='exportDialog.active' persistent max-width='45%'>
      <v-card v-if='exportDialog.active' style='background-color: var(--v-background-base);'>
        <v-app-bar dark color='primary'>
          <v-tooltip bottom>
            <template #activator='{ on }'>
              <v-btn icon v-on='on' @click.native='exportDialog.active = false'>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{$t('CLOSE')}}</span>
          </v-tooltip>

          <div class='text-h7 ml-2'>
            {{ $t('CUSTOMER_EXPORT') }}
          </div>
        </v-app-bar>
        <v-card-text class='pa-5'>
          <v-autocomplete
            v-model='exportDialog.selectedCustomer'
            outlined
            clearable
            item-text='name'
            item-value='id'
            color='primary'
            :label='$t("CUSTOMER")'
            :items='list'
            @change='getCustomerServices()'
          >
            <template #selection='data'>
              <v-chip
                v-bind='data.attrs'
                close
                color='primary'
                :input-value='data.selected'
                @click:close='exportDialog.selectedCustomer = undefined'
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model='exportDialog.selectedServices'
            outlined
            multiple
            clearable
            item-text='name'
            item-value='id'
            color='primary'
            :label='$t("SERVICES")'
            :items='exportDialog.servicesList'
          >
            <template #selection='data'>
              <v-chip
                v-bind='data.attrs'
                close
                color='primary'
                :input-value='data.selected'
                @click:close='removeService(data.item)'
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-col class='pa-0'>
            <v-list-item-title class='mb-5 color-primary'>Data de expiração de contrato</v-list-item-title>
            <v-row no-gutters>
              <v-col cols='6'>
                <v-text-field v-model='exportDialog.expirationStartDate' class='mr-5' label='Data inicial' type='date' outlined hide-details clearable />
              </v-col>
              <v-col cols='6'>
                <v-text-field v-model='exportDialog.expirationEndDate' class='ml-5' label='Data final' type='date' outlined hide-details clearable />
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-row class='px-1 pb-3' no-gutters>
            <v-spacer />
            <v-btn class='mr-2' @click='exportDialog.active = false'>{{ $t('CANCEL') }}</v-btn>
            <v-btn class='ml-2' color='primary' @click='exportCustomers()'>{{ $t('EXPORT') }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='detailsDialog' fullscreen persistent no-click-animation>
      <v-card v-if='detailsDialog' style='background-color: var(--v-background-base);'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='detailsDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('CUSTOMER')}}
            </div>
          </v-app-bar>

          <customers-details :is-editing='isEditing' :customer-id='customerId' @new-customer='getData' @edit-customer='handleOnConfirmEdit' @close='detailsDialog = false' />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { jsonToExcel } from '@/util/excel';
  import axios from 'axios';
  import { format, parseISO, formatDistanceToNow } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';

  export default {
    name: 'Customers',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      CustomersDetails: () => import('./CustomersDetails'),
    },
    data: function () {
      return {
        list: undefined,
        search: undefined,
        customerTableHeaders: [
          { text: this.$t('NAME'), value: 'name' },
          { text: this.$t('CNPJ'), value: 'cnpj' },
          { text: this.$t('EMAIL'), value: 'email' },
          { text: this.$t('PHONE'), value: 'phone' },
          { text: this.$t('ACRONYM'), value: 'acronym' },
          { text: this.$t('FINGERPRINT_SIGNIN'), value: 'allowFingerprintSignin' },
          { text: this.$t('CREATION_DATE'), value: 'createdAt' },
          { text: this.$t('UPDATE_DATE'), value: 'updatedAt' },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: '30',
            align: 'center',
          },
        ],
        customerId: 0,
        isEditing: false,
        detailsDialog: false,
        customerAccessDataDialog: false,
        selectedCustomer: undefined,
        exportDialog: {
          active: false,
          selectedCustomer: undefined,
          selectedServices: [],
          servicesList: [],
          expirationStartDate: undefined,
          expirationEndDate: undefined,
        },
      };
    },
    mounted: function () {
      this.getData();
      this.getAvailableServices();
    },
    methods: {
      getAvailableServices: async function () {
        const { data } = await axios({
          url: '/account-get-available-modules',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.exportDialog.servicesList = data.list;
        this.exportDialog.selectedServices = this.exportDialog.servicesList.map((service) => service.id);
      },
      getCustomerServices: async function () {
        if (!this.exportDialog.selectedCustomer) {
          this.getAvailableServices();
          return;
        }
        const { data } = await axios({
          url: `/customer/${this.exportDialog.selectedCustomer}/available-services`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.exportDialog.servicesList = data.services;
        this.exportDialog.selectedServices = this.exportDialog.servicesList.map((service) => service.id);
      },
      removeService: function (item) {
        this.exportDialog.selectedServices = this.exportDialog.selectedServices.filter((ss) => ss !== item.id);
      },
      handleOnClickEdit: function (id) {
        this.customerId = id;
        this.isEditing = true;
        this.detailsDialog = true;
      },
      handleOnConfirmEdit: function () {
        this.customerId = 0;
        this.isEditing = false;
        this.getData();
      },
      openCustomerAccessDataDialog: function (item) {
        this.selectedCustomer = item;
        this.customerAccessDataDialog = true;
      },
      closeCustomerAccessDataDialog: function () {
        this.customerAccessDataDialog = false;
        this.selectedCustomer = undefined;
      },
      formattedDate: function (value, mask) {
        if (!value) return '';
        return format(parseISO(value), mask || 'dd/MM/yyyy HH:mm');
      },
      formattedDateInWords: function (value) {
        if (!value) return '';
        return formatDistanceToNow(parseISO(value), { locale: ptBr });
      },
      getTableRowClass: function (item) {
        if (!item.active) {
          return 'red lighten-3 grey--text text--darken-2';
        }

        return '';
      },
      getData: async function () {
        try {
          const { data } = await axios({
            url: '/customer',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.list = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      openExportDialog: function () {
        this.exportDialog.active = true;
      },
      exportCustomers: async function () {
        const params = {};

        if (this.exportDialog.selectedCustomer) {
          params.customer = this.exportDialog.selectedCustomer;
        }

        if (this.exportDialog.selectedServices) {
          params.services = encodeURIComponent(this.exportDialog.selectedServices);
        }

        if (this.exportDialog.expirationStartDate) {
          params.startDate = this.exportDialog.expirationStartDate;
        }

        if (this.exportDialog.expirationEndDate) {
          params.endDate = this.exportDialog.expirationEndDate;
        }

        const { data } = await axios({
          url: '/customers/export',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          params,
          responseType: 'json',
          withCredentials: true,
        });

        let customerName;
        const content = [];

        data.forEach((customer) => {
          if (customerName !== customer.name) {
            customerName = customer.name;
            if (content.length) {
              content.push({});
            }
          } else {
            delete customer.name;
            delete customer.cnpj;
            delete customer.description;
            delete customer.email;
            delete customer.phone;
            delete customer.createdAt;
          }
          content.push({
            Cliente: customer.name,
            CNPJ: customer.cnpj,
            Email: customer.email,
            Telefone: customer.phone,
            'Data de criação': customer.createdAt ? this.formattedDate(customer.createdAt, 'dd/MM/yyyy') : '',
            Serviço: customer.service,
            Especificações: customer.specifications || 'N/A',
            'Data limite de contrato': customer.expirationDate ? this.formattedDate(customer.expirationDate, 'dd/MM/yyyy') : '',
            'Nº de instalações': customer.installations || 'N/A',
          });
        });

        jsonToExcel({
          fileName: `Relatório de clientes - ${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}`,
          values: content,
        });
      },
    },
  };
</script>
