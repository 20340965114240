import * as XLSX from 'xlsx';

interface ISheet {
  name: string,
  values: any[],
}

interface IJSONToExcel {
  values: any[], // Used for single sheet if no Sheets array is provided
  sheets?: ISheet[],
  fileName?: string,
}

function cellWrapText (worksheet: XLSX.WorkSheet): void {
  Object.keys(worksheet).forEach((key: any) => {
    if (key[0] !== '!') {
      worksheet[key].s = {
        alignment: {
          wrapText: true,
        },
      };
    }
  });
}

export function jsonToExcel (options: IJSONToExcel): void {
  const { sheets, values, fileName } = options;
  const workbook = XLSX.utils.book_new();

  if (sheets && sheets.length > 0) {
    sheets.forEach((sheet: ISheet) => {
      const worksheet = XLSX.utils.json_to_sheet(sheet.values);

      cellWrapText(worksheet);

      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name || 'Sheet1');
    });
  } else {
    const worksheet = XLSX.utils.json_to_sheet(values);

    cellWrapText(worksheet);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  }

  XLSX.writeFile(workbook, `${fileName ?? 'export'}.xlsx`);
}

export default {
  jsonToExcel,
};
